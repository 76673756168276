import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
//import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { TextField } from "@material-ui/core";
//import CircularProgress from "@material-ui/core/CircularProgress";
import AWSLoginButton from './AWSLoginButton'
import AWSLoginButtonFalse from './AWSLoginButtonFalse'
import GoogleLoginButton from './GoogleLoginButton'
import FaceBookLoginButton from './FaceBookLoginButton'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

// AWS Amplify를 사용하여 Amazon Cognito 설정에 로그인
import { Auth, API } from "aws-amplify";
import { connect } from 'react-redux'
import * as authActions from 'redux/actions/auth'

// 라우터 관련
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';

// 다음 주소 api
import DaumPostcode from 'react-daum-postcode';

import SVGIcon from '../SVGIcon'

//import './Mypage.scss'
//import './AWSSignupForm.scss'
//import './AWSInput.scss'

const styles = theme => ({
  root: {
    width : '100%',
    //minWidth: 1080,
    marginTop : theme.spacing(1),
    overFlowX : "auto",
    display: 'flex',
    paddingBottom : theme.spacing(7),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#F5F5F5",
    boxShadow: "none",
  },
  // toolbar: theme.mixins.toolbar,
  toolBar: {
    height : 10,
  },
  appbarTitle: {
    flexGrow: 1,
    fontSize : 18,
    /*  이거 넣으면 sm될때 사라짐
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginRight: theme.spacing(5)
    */
  },
  logoMedia: {
    width: 50,
    height: 50,
  },
  content1: {
    marginTop: '40px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  content2: {
    marginTop: '60px',
    flexGrow: 1,
    //padding: theme.spacing(0),
    marginBottom: '60px'
  },
  // step관련
  stepperRoot: {
    marginTop: 20,
    width: '100%',
  },
  stepper: {
    // background: "#F5F5F5"
  },
  step: {
    //fontSize: 20,
  },
  stepLabelRoot: {
    "&$completed": {
      color: "#f44336",  // red
    },
    "&$active": {
      color: "#0c93d0",  // ilgam color
    },
  },
  //
  iconButton: {
    margin: theme.spacing(0),
  },
  labelTop: {
    marginTop: 20,
    display: 'block',
    fontSize: 15,
    marginBottom: 8,
  },
  label: {
    display: 'block',
    fontSize: 15,
    marginBottom: 8,
  },
  addressButton: {
    width: 150,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  codeButtonTrue: {
    fontSize: 15,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  codeButtonFalse: {
    fontSize: 18,
  },
  nextButton: {
    fontSize: 18,
    width: 150,
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  /*
  submitButtonTrue: {
    fontSize: 18,
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  submitButtonFalse: {
    fontSize: 18,
  },
  */
  dialogButton: {
    width: '100%',
    backgroundColor: '#0c93d0', // 일감 로고 색깔 (#067fb5)
    color: "#ffffff",
    '&:hover': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      color: "#ffffff",
      backgroundColor: '#067fb5',
      borderColor: '#005cbf',
      boxShadow: 'none',
    },
    '&:focus': {
      color: "#ffffff",
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  buttonChecked: {
    color: '#0c93d0',
    '&$checked': {
      color: '#0c93d0',
    },
  },
  buttonLine: {
    color: '#0c93d0',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  svgIcon: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "sans-serif",
    justifyContent: "space-between"
  },
  typoSubmit: {
    fontSize: 18
  },
  card: {
    maxWidth: 850,
    // width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
  card2: {
    maxWidth: 200,
    marginTop: 5,
    marginBottom: 15,
    boxShadow: "none",
  },
  list: {
    width: '100%',
    padding: '0',
  },
  listItemTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  listItem: {
    height: 50,
  },
  listItemIcon: {
    minWidth: 0
  },
  typoMainTitle1: {
    width: '100%',
    fontSize : 20,
    paddingTop: 25,
    paddingBottom: 0,
  },
  typoMainTitle2: {
    width: '100%',
    fontSize : 20,
    paddingTop: 5,
    paddingBottom: 0,
  },
  typoMainTitle3: {
    width: '100%',
    fontSize : 14,
    paddingTop: 10,
    paddingBottom: 30,
  },
  typoTitle: {
    width: '100%',
    fontSize : 16,
    paddingTop: 0,
    paddingBottom: 0,
    background: "#E8E8E8"  // 살짝 짙은 grey
  },
  cardHeader: {
    width: '100%',
    paddingRight: 0,
  },
  textField : {
    width: '100%',
    fontSize: 18,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '20px',
    paddingRight: '24px',
    '& .MuiInput-underline:before': {
      // borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#0c93d0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0c93d0',
    },
  },
  textFieldSize: {
    fontSize: 18,
  },
  // PC버전에서는 fontSize가 18, 모바일은 13
  typoItemSelect: {
    fontSize: 13,
    paddingTop: '0%',
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  typoTerms: {
    width: '100%',
    fontSize: 18,
    border: '1px solid light-grey',
    borderRadius: 5,
    paddingLeft: '0px',
    paddingRight: '24px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  /*
  googleButton: {
    marginTop: 20,
    fontSize: 15
  },
  facebookButton: {
    marginTop: 20,
    fontSize: 15
  },
  */
  progress: {
    //margin: theme.spacing(2),
    // position: 'absolute',
    position: 'fixed',
    //left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#0c93d0',
  },
  LoginForm__input: {
    marginBottom: '16px',
    '& input': {
      paddingLeft: '48px',
      background: "url('/images/icon-lock.png') 16px center / 24px no-repeat",
    },
  },
  Input__input: {
    width: '100%',
    //borderWidth: 0,
    boxSizing: 'border-box',
    fontSize: '15px',
    border: '1px solid #c4c4c4', // replace $light-grey with #c4c4c4
    padding: '22px 24px',
    borderRadius: '5px',
    '&::placeholder': {
      color: '#8e8e8e', // replace $middle-grey with #8e8e8e
    },
    '&--err': {
      borderColor: '#f44336', // replace $alert-red with #f44336
    },
  }
});

/*
// Facebook JS SDK가로드 될 때까지 기다립니다. 
// 일단로드되면 Facebook으로 로그인 버튼을 활성화 합니다.
function waitForInit() {
  return new Promise((res, rej) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        res();
      } else {
        setTimeout(hasFbLoaded, 300);
      }
    };
    hasFbLoaded();
  });
}
*/

class AWSSignupForm_Buyer_Company extends Component {

  steps =  ['Step 1', 'Step 2'];

  constructor(props) {
    super(props);

    this.signIn = this.signIn.bind(this);
    
    this.state = {
      pageStep: 0,
      // Stepper
      activeStep: 0, 
      completedStep: [],
      // 페이지 1
      userContent: '',
      file: '',
      fileName: '',
      warningMessage: '',
      isCompressing: false,
      user: null,
      userId: "",
      avatarAttachmentURL: null,
      customerType1: "",
      customerType2: "",
      walletAddress: "",
      companyName: "",
      avatarimage: "",
      tempAvatarImage: null,
      ceoName: '',
      companyNumber: '',
      companyPhoneNumber: '',
      companyFaxNumber: '',
      zoneCode: '',
      address_1: '',
      address_2: '',
      address_3: '',
      address_4: '',
      address: [],
      homepage: '',
      history: '',
      name: '',
      position: '',
      phoneNumber: '',
      email: '',
      adminConfirm: '',
      level: '',
      isLoading: false,
      postCodeDialogOpen: false,
      checkedMain: [],
      checkedSub: [],
      checked_0_0: false,
      checked_0_1: false,
      checked_0_2: false,
      checked_0_3: false,
      checked_1_0: false,
      checked_1_1: false,
      checked_1_2: false,
      checked_1_3: false,
      checked_1_4: false,
      checked_1_5: false,
      checked_2_0: false,
      checked_2_1: false,
      checked_2_2: false,
      checked_2_3: false,
      checked_2_4: false,
      checked_3_0: false,
      checked_3_1: false,
      checked_3_2: false,
      checked_3_3: false,
      checked_3_4: false,
      checked_3_5: false,
      checked_4_0: false,
      checked_4_1: false,
      checked_4_2: false,
      checked_4_3: false,
      checked_4_4: false,
      checked_4_5: false,
      checked_4_6: false,
      checked_4_7: false,
      checked_5_0: false,
      checked_5_1: false,
      checked_5_2: false,
      checked_5_3: false,
      checked_5_4: false,
      checked_5_5: false,
      checked_5_6: false,
      checked_5_7: false,
      checked_5_8: false,
      checked_6_0: false,
      checked_6_1: false,
      checked_6_2: false,
      checked_6_3: false,
      checked_6_4: false,
      checked_7_0: false,
      checked_7_1: false,
      checked_7_2: false,
      checked_7_3: false,
      checked_7_4: false,
      checked_7_5: false,
      checked_8_0: false,
      checked_8_1: false,
      checked_8_2: false,
      checked_8_3: false,
      checked_8_4: false,
      checked_8_5: false,
      checked_9_0: false,
      checkedTerms: [],
      checkedTerms_all: false,
      checkedTerms_0: false,
      checkedTerms_1: false,
      checkedTerms_2: false,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
      alarmDialogOpen_5: false,
      alarmDialogOpen_6: false,
      alarmDialogOpen_7: false,
      alarmDialogOpen_8: false,
      alarmDialogOpen_9: false,
      alarmDialogOpen_10: false,
      alarmDialogOpen_11: false,
      alarmDialogOpen_12: false,
      alarmDialogOpen_13: false,
      alarmDialogOpen_14: false,
      alarmDialogOpen_15: false,
      alarmDialogOpen_16: false,
      alarmDialogOpen_17: false,
      completed : 0,
      attachmentURLs: [],
      images: [],
      tempUploadImage: false,
      tempS3ImageURLs: [],  // S3에서 받은 이미지를 임시로 저장(Cancel 버튼을 위해서)
      tempS3ImageToggle: true,
      // 페이지 2
      privateKey: null,
      isLoading: false,
      warningMessage: '',
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      validateform: true,
      completed : 0,
    };
  }

  handleStep = (step) => () => {
    this.setState({activeStep: step})
  };
  //

  // 뒤로 Back
  handleGoBack = () => {
    this.props.history.goBack();  
  }

  // 실제로 데이터가 수정되는 것을 도와주기 위한 함수
  handleNext = async event => {
    event.preventDefault();

    // 작성 안한 파트 작성하게끔 해주는 코드
    if (!this.state.companyName) {
      this.setState({alarmDialogOpen_1: true});
      return;
    }
    if (!this.state.companyNumber) {
      this.setState({alarmDialogOpen_2: true});
      return;
    }
    if (!this.state.companyPhoneNumber) {
      this.setState({alarmDialogOpen_3: true});
      return;
    }
    if (!this.state.history) {
      this.setState({alarmDialogOpen_5: true});
      return;
    }
    if (!this.state.address_1) {
      this.setState({alarmDialogOpen_4: true});
      return;
    }
    if (!this.state.name) {
      this.setState({alarmDialogOpen_6: true});
      return;
    }
    if (!this.state.phoneNumber) {
      this.setState({alarmDialogOpen_7: true});
      return;
    }

    //--------------------------------------------------
    // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
    let arrayMain = [];
    let arraySub = [];

    arrayMain[0] = this.state.checked_0_0.toString();
    arraySub[0] = this.state.checked_0_1.toString();
    arraySub[1] = this.state.checked_0_2.toString();
    arraySub[2] = this.state.checked_0_3.toString();

    arrayMain[1] = this.state.checked_1_0.toString();
    arraySub[3] = this.state.checked_1_1.toString();
    arraySub[4] = this.state.checked_1_2.toString();
    arraySub[5] = this.state.checked_1_3.toString();
    arraySub[6] = this.state.checked_1_4.toString();
    arraySub[7] = this.state.checked_1_5.toString();

    arrayMain[2] = this.state.checked_2_0.toString();
    arraySub[8] = this.state.checked_2_1.toString();
    arraySub[9] = this.state.checked_2_2.toString();
    arraySub[10] = this.state.checked_2_3.toString();
    arraySub[11] = this.state.checked_2_4.toString();

    arrayMain[3] = this.state.checked_3_0.toString();
    arraySub[12] = this.state.checked_3_1.toString();
    arraySub[13] = this.state.checked_3_2.toString();
    arraySub[14] = this.state.checked_3_3.toString();
    arraySub[15] = this.state.checked_3_4.toString();
    arraySub[16] = this.state.checked_3_5.toString();

    arrayMain[4] = this.state.checked_4_0.toString();
    arraySub[17] = this.state.checked_4_1.toString();
    arraySub[18] = this.state.checked_4_2.toString();
    arraySub[19] = this.state.checked_4_3.toString();
    arraySub[20] = this.state.checked_4_4.toString();
    arraySub[21] = this.state.checked_4_5.toString();
    arraySub[22] = this.state.checked_4_6.toString();
    arraySub[23] = this.state.checked_4_7.toString();

    arrayMain[5] = this.state.checked_5_0.toString();
    arraySub[24] = this.state.checked_5_1.toString();
    arraySub[25] = this.state.checked_5_2.toString();
    arraySub[26] = this.state.checked_5_3.toString();
    arraySub[27] = this.state.checked_5_4.toString();
    arraySub[28] = this.state.checked_5_5.toString();
    arraySub[29] = this.state.checked_5_6.toString();
    arraySub[30] = this.state.checked_5_7.toString();
    arraySub[31] = this.state.checked_5_8.toString();

    arrayMain[6] = this.state.checked_6_0.toString();
    arraySub[32] = this.state.checked_6_1.toString();
    arraySub[33] = this.state.checked_6_2.toString();
    arraySub[34] = this.state.checked_6_3.toString();
    arraySub[35] = this.state.checked_6_4.toString();

    arrayMain[7] = this.state.checked_7_0.toString();
    arraySub[36] = this.state.checked_7_1.toString();
    arraySub[37] = this.state.checked_7_2.toString();
    arraySub[38] = this.state.checked_7_3.toString();
    arraySub[39] = this.state.checked_7_4.toString();
    arraySub[40] = this.state.checked_7_5.toString();

    arrayMain[8] = this.state.checked_8_0.toString();
    arraySub[41] = this.state.checked_8_1.toString();
    arraySub[42] = this.state.checked_8_2.toString();
    arraySub[43] = this.state.checked_8_3.toString();
    arraySub[44] = this.state.checked_8_4.toString();
    arraySub[45] = this.state.checked_8_5.toString();

    arrayMain[9] = this.state.checked_9_0.toString();

    this.state.checkedMain = arrayMain;
    this.state.checkedSub = arraySub;

    let trueCountNumMain = 0;
    let trueCountNumSub = 0;

    for(let i=0;i<10;i++) {
      if(this.state.checkedMain[i].toLowerCase() == 'true' ? true : false === true) {
        trueCountNumMain = trueCountNumMain + 1
      }
    }

    if (trueCountNumMain === 0) {
      this.setState({alarmDialogOpen_9: true});
      return;
    }

    for(let i=0;i<46;i++) {
      if(this.state.checkedSub[i].toLowerCase() == 'true' ? true : false === true) {
        trueCountNumSub = trueCountNumSub + 1
      }
    }

    if(this.state.checkedMain[0].toLowerCase() == 'false' && this.state.checkedMain[1].toLowerCase() == 'false'
      && this.state.checkedMain[2].toLowerCase() == 'false' && this.state.checkedMain[3].toLowerCase() == 'false'
      && this.state.checkedMain[4].toLowerCase() == 'false' && this.state.checkedMain[5].toLowerCase() == 'false'
      && this.state.checkedMain[6].toLowerCase() == 'false' && this.state.checkedMain[7].toLowerCase() == 'false'
      && this.state.checkedMain[8].toLowerCase() == 'false' && this.state.checkedMain[9].toLowerCase() == 'true'
    ) {
      //console.log("pass")
    } else {
      if (trueCountNumSub === 0) {
        this.setState({alarmDialogOpen_10: true});
        return;
      }
    }
    
    //--------------------------------------------------
    // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
    if (this.state.checkedTerms_0 === false || this.state.checkedTerms_1 === false) {
      this.setState({alarmDialogOpen_11: true});
      return;
    }

    let arrayTerms = [];

    arrayTerms[0] = this.state.checkedTerms_0.toString();
    arrayTerms[1] = this.state.checkedTerms_1.toString();
    arrayTerms[2] = this.state.checkedTerms_2.toString();

    this.state.checkedTerms = arrayTerms;

    //--------------------------------------------------
    if(this.state.walletAddress === '') {
      this.state.walletAddress = "default"
    }
    if(this.state.avatarimage === '') {
      this.state.avatarimage = 'No_File'
    }
    if(this.state.ceoName === '') {
      this.state.ceoName = "default"
    }
    if(this.state.companyFaxNumber === '') {
      this.state.companyFaxNumber = "default"
    }
    if(this.state.address_2 === '') {
      this.state.address_2 = "default"
    }
    if(this.state.homepage === '') {
      this.state.homepage = "default"
    }
    if(this.state.position === '') {
      this.state.position = "default"
    }
    
    let array2 = [];
    array2[0] = this.state.address_1
    array2[1] = this.state.address_2
    array2[2] = this.state.address_3
    array2[3] = this.state.address_4

    this.state.address = array2

    let noFiles = []
    for(let i=0;i<9;i++) {
      noFiles.push('No_File')
    }
    this.state.images = noFiles;
    //--------------------------------------------------

    // 2번째 페이지로 넘어감
    this.setState({
      pageStep: 1,
      activeStep: 1,
    });

    this.state.completedStep[0] = true
  }

  // 다음 주소 api
  handleAddress = (data) => {
    let fullAddress = data.address;
    let zonecode = data.zonecode;
    let sido = data.sido;
    let sigungu = data.sigungu;
    let extraAddress = ''; 

    // const { zonecode, address_1 } = this.props;
  
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }

    this.setState({
      zoneCode: zonecode,
      address_1: fullAddress,
      address_3: sido,
      address_4: sigungu,
      postCodeDialogOpen: false
    })

    // console.log(zonecode);  // e.g. '07067'
    // console.log(fullAddress);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    // console.log(sido);  // e.g. '경기'
    // console.log(sigungu);  // '성남시 분당구'
    // console.log(this.state.address_3);  // '경기 성남시 분당구'
  }

  handlePostCodeDialog = () => {
    this.setState({postCodeDialogOpen: true})
  }

  // 닫기 창을 눌렀을때 실행되는 함수
  handleClose = () => {
    this.setState({
      postCodeDialogOpen: false,
      alarmDialogOpen_1: false,
      alarmDialogOpen_2: false,
      alarmDialogOpen_3: false,
      alarmDialogOpen_4: false,
      alarmDialogOpen_5: false,
      alarmDialogOpen_6: false,
      alarmDialogOpen_7: false,
      alarmDialogOpen_8: false,
      alarmDialogOpen_9: false,
      alarmDialogOpen_10: false,
      alarmDialogOpen_11: false,
      alarmDialogOpen_12: false,
      alarmDialogOpen_13: false,
      alarmDialogOpen_14: false,
      alarmDialogOpen_15: false,
      alarmDialogOpen_16: false,
    })
  }

  handleCheckedChange = name => event => {
    this.setState({ [name] : event.target.checked });

    if(name === 'checked_0_0') {
      if(this.state.checked_0_0 === false) {
        this.setState({
          checked_0_1 : false,
          checked_0_2 : false,
          checked_0_3 : false,       
        })
      }
    }
    if(name === 'checked_1_0') {
      if(this.state.checked_1_0 === false) {
        this.setState({
          checked_1_1 : false,
          checked_1_2 : false,
          checked_1_3 : false,    
          checked_1_4 : false,    
          checked_1_5 : false,    
        })
      }
    }
    if(name === 'checked_2_0') {
      if(this.state.checked_2_0 === false) {
        this.setState({
          checked_2_1 : false,
          checked_2_2 : false,
          checked_2_3 : false,    
          checked_2_4 : false,      
        })
      }
    }
    if(name === 'checked_3_0') {
      if(this.state.checked_3_0 === false) {
        this.setState({
          checked_3_1 : false,
          checked_3_2 : false,
          checked_3_3 : false,    
          checked_3_4 : false,   
          checked_3_5 : false,   
        })
      }
    }
    if(name === 'checked_4_0') {
      if(this.state.checked_4_0 === false) {
        this.setState({
          checked_4_1 : false,
          checked_4_2 : false,
          checked_4_3 : false,    
          checked_4_4 : false,   
          checked_4_5 : false,  
          checked_4_6 : false,  
          checked_4_7 : false,  
        })
      }
    }
    if(name === 'checked_5_0') {
      if(this.state.checked_5_0 === false) {
        this.setState({
          checked_5_1 : false,
          checked_5_2 : false,
          checked_5_3 : false,    
          checked_5_4 : false,   
          checked_5_5 : false,  
          checked_5_6 : false,  
          checked_5_7 : false,  
          checked_5_8 : false,  
        })
      }
    }
    if(name === 'checked_6_0') {
      if(this.state.checked_4_0 === false) {
        this.setState({
          checked_6_1 : false,
          checked_6_2 : false,
          checked_6_3 : false,    
          checked_6_4 : false,   
        })
      }
    }
    if(name === 'checked_7_0') {
      if(this.state.checked_7_0 === false) {
        this.setState({
          checked_7_1 : false,
          checked_7_2 : false,
          checked_7_3 : false,    
          checked_7_4 : false,   
          checked_7_5 : false,  
        })
      }
    }

    if(name === 'checked_8_0') {
      if(this.state.checked_8_0 === false) {
        this.setState({
          checked_8_1 : false,
          checked_8_2 : false,
          checked_8_3 : false,    
          checked_8_4 : false,   
          checked_8_5 : false,  
        })
      }
    }

    //--------------------------------------------------
    // 분류 체크란 checked 배열로 저장해서 DB저장 위한 코드
    let arrayMain = [];
    let arraySub = [];

    arrayMain[0] = this.state.checked_0_0.toString();
    arraySub[0] = this.state.checked_0_1.toString();
    arraySub[1] = this.state.checked_0_2.toString();
    arraySub[2] = this.state.checked_0_3.toString();

    arrayMain[1] = this.state.checked_1_0.toString();
    arraySub[3] = this.state.checked_1_1.toString();
    arraySub[4] = this.state.checked_1_2.toString();
    arraySub[5] = this.state.checked_1_3.toString();
    arraySub[6] = this.state.checked_1_4.toString();
    arraySub[7] = this.state.checked_1_5.toString();

    arrayMain[2] = this.state.checked_2_0.toString();
    arraySub[8] = this.state.checked_2_1.toString();
    arraySub[9] = this.state.checked_2_2.toString();
    arraySub[10] = this.state.checked_2_3.toString();
    arraySub[11] = this.state.checked_2_4.toString();

    arrayMain[3] = this.state.checked_3_0.toString();
    arraySub[12] = this.state.checked_3_1.toString();
    arraySub[13] = this.state.checked_3_2.toString();
    arraySub[14] = this.state.checked_3_3.toString();
    arraySub[15] = this.state.checked_3_4.toString();
    arraySub[16] = this.state.checked_3_5.toString();

    arrayMain[4] = this.state.checked_4_0.toString();
    arraySub[17] = this.state.checked_4_1.toString();
    arraySub[18] = this.state.checked_4_2.toString();
    arraySub[19] = this.state.checked_4_3.toString();
    arraySub[20] = this.state.checked_4_4.toString();
    arraySub[21] = this.state.checked_4_5.toString();
    arraySub[22] = this.state.checked_4_6.toString();
    arraySub[23] = this.state.checked_4_7.toString()
    arrayMain[5] = this.state.checked_5_0.toString();
    arraySub[24] = this.state.checked_5_1.toString();
    arraySub[25] = this.state.checked_5_2.toString();
    arraySub[26] = this.state.checked_5_3.toString();
    arraySub[27] = this.state.checked_5_4.toString();
    arraySub[28] = this.state.checked_5_5.toString();
    arraySub[29] = this.state.checked_5_6.toString();
    arraySub[30] = this.state.checked_5_7.toString();
    arraySub[31] = this.state.checked_5_8.toString();

    arrayMain[6] = this.state.checked_6_0.toString();
    arraySub[32] = this.state.checked_6_1.toString();
    arraySub[33] = this.state.checked_6_2.toString();
    arraySub[34] = this.state.checked_6_3.toString();
    arraySub[35] = this.state.checked_6_4.toString();

    arrayMain[7] = this.state.checked_7_0.toString();
    arraySub[36] = this.state.checked_7_1.toString();
    arraySub[37] = this.state.checked_7_2.toString();
    arraySub[38] = this.state.checked_7_3.toString();
    arraySub[39] = this.state.checked_7_4.toString();
    arraySub[40] = this.state.checked_7_5.toString();

    arrayMain[8] = this.state.checked_8_0.toString();
    arraySub[41] = this.state.checked_8_1.toString();
    arraySub[42] = this.state.checked_8_2.toString();
    arraySub[43] = this.state.checked_8_3.toString();
    arraySub[44] = this.state.checked_8_4.toString();
    arraySub[45] = this.state.checked_8_5.toString();

    arrayMain[9] = this.state.checked_9_0.toString();

    this.state.checkedMain = arrayMain;
    this.state.checkedSub = arraySub;
  }

  handleCheckedTerms = name => event => {
    this.setState({ [name] : event.target.checked });

    if(name === 'checkedTerms_all') {
      if(this.state.checkedTerms_all === true) {
        this.setState({
          checkedTerms_0 : false,
          checkedTerms_1 : false,
          checkedTerms_2 : false,       
        })
      } else if(this.state.checkedTerms_all === false) {
        this.setState({
          checkedTerms_0 : true,
          checkedTerms_1 : true,
          checkedTerms_2 : true,       
        })
      }
    }

    let arrayTerms = [];

    arrayTerms[0] = this.state.checkedTerms_0.toString();
    arrayTerms[1] = this.state.checkedTerms_1.toString();
    arrayTerms[2] = this.state.checkedTerms_2.toString();

    this.state.checkedTerms = arrayTerms;
  }

  handleMemberTerms = () => {
    window.open('/memberterms')  // 새로운 탭에서 열림
  }

  handleInfoAgreememnt = () => {
    window.open('/infoAgreememnt')  // 새로운 탭에서 열림
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  selectField() {
    const { classes } = this.props;
    const { } = this.state

    const connector = <StepConnector 
      classes={{
        //active: classes.connectorActive,
        //completed: classes.connectorCompleted,
        line: classes.connectorLine
      }} />;

    const stepper = (
      <div className={classes.stepperRoot}>
        <Stepper nonLinear activeStep={this.state.activeStep} className={classes.stepper} connector={connector}>
          {this.steps.map((label, index) => (
            <Step key={label} className={classes.step}>
                <StepLabel
                  completed={this.state.completedStep[index]}
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                    iconContainer: classes.iconContainer
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.stepLabelRoot,
                      completed: classes.completed,
                      active: classes.active,
                      text: classes.text
                    }
                  }}
                >
                </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );

    return (
      <div>
        {/* 네비게이션 바 */}
        <div className={classes.root}>
          {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
          <AppBar position="fixed" color="default" className={classes.appBar}>
            <Toolbar className={classes.toolBar} variant="dense">
              <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <IconButton
                    edge="start"
                    className={classes.iconButton}
                    color="inherit"
                    aria-label="back"
                    onClick={this.handleGoBack}
                  >
                    <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <CardMedia
                    className={classes.logoMedia}
                    // image="/static/images/logo/app.svg"
                    image="/images/logo/app.svg"
                    aria-label="Recipe" 
                  />
                </Grid>
                <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                  <Typography className={classes.appbarTitle} align='center' noWrap>
                    오투공 - Online To 공장
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            </Toolbar>
            <Divider />
          </AppBar>
          <main className={classes.content1}>
            {/* <div className={classes.toolbar} /> */}
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid item xs={8} sm={8} md={4} lg={3} xl={3} align="center">
                  <div>{stepper}</div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Typography className={classes.typoMainTitle1}>
                  안녕하세요, 고객님.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Typography className={classes.typoMainTitle2}>
                  아래의 정보를 기입하시면 매칭시 신뢰도가 올라갑니다.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Typography className={classes.typoMainTitle3}>
                  관심분야를 알려주시면 서비스를 제공하는 공장을 연결 시켜 드립니다.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card}>
                  <List className={classes.list}>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>업체명(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                        <TextField
                            className={classes.textField}
                            id="companyName"
                            name="companyName"
                            autoFocus
                            value={this.state.companyName}
                            onChange={this.handleValueChange}
                            placeholder="'(주)'없이 입력바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>사업자 등록번호(*)</Typography>
                        </ListItem>
                        <Divider/>  
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="companyNumber"
                            name="companyNumber"
                            // type="number"
                            autoFocus
                            value={this.state.companyNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-'없이 입력 바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>대표번호(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="companyPhoneNumber"
                            name="companyPhoneNumber"
                            // type="number"
                            autoFocus
                            value={this.state.companyPhoneNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-'없이 입력 바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>일감 분야(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="history"
                            name="history"
                            autoFocus
                            value={this.state.history}
                            onChange={this.handleValueChange}
                            placeholder="예) 정밀 원통연마 외주, 유휴 MCT 설비 1대"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>주소(업체와 매칭을 위해서 필요합니다*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="zoneCode"
                            name="zoneCode"
                            // type="number"
                            autoFocus
                            value={this.state.zoneCode}
                            onChange={this.handleValueChange}
                            placeholder="우편번호"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <ListItem className={classes.listItem}>
                          <Button variant="contained" /*color="primary"*/ className={classes.addressButton}
                            onClick={this.handlePostCodeDialog}
                          >
                            우편번호 검색
                          </Button>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="address_1"
                            name="address_1"
                            autoFocus
                            value={this.state.address_1}
                            onChange={this.handleValueChange}
                            placeholder="회사 주소"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="address_2"
                            name="address_2"
                            autoFocus
                            value={this.state.address_2}
                            onChange={this.handleValueChange}
                            placeholder="상세 주소"
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>담당자 이름(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleValueChange}
                            placeholder=""
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Divider/>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>연락처(*)</Typography>
                        </ListItem>
                        <Divider/>
                        <ListItem className={classes.listItem}>
                          <TextField
                            className={classes.textField}
                            id="phoneNumber"
                            name="phoneNumber"
                            // type="number"
                            autoFocus
                            value={this.state.phoneNumber}
                            onChange={this.handleValueChange}
                            placeholder="'-'없이 입력 바랍니다."
                            autoComplete="off"
                            InputProps={{
                              classes: {
                                input: classes.textFieldSize,
                              },
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>    
                    <Divider/>
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>대표 보유기술 및 관심 분야(*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_0')}
                              value="checked_0_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>4차 산업분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_0')}
                              value="checked_1_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>설계 분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_0')}
                              value="checked_2_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>PLC 자동화</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_0')}
                              value="checked_3_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>조립, 유지보수</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_0')}
                              value="checked_4_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>가공 분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_0')}
                              value="checked_5_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>연마 분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_0')}
                              value="checked_6_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>용접 및 마킹분야</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_0')}
                              value="checked_7_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타 (가공공정 관련)</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_0')}
                              value="checked_8_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>통역, 번역</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_9_0}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_9_0')}
                              value="checked_9_0"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>공장 공유</Typography>}/>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>상세 분야(*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    { this.state.checked_0_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_1')}
                              value="checked_0_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>3D 프린터</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_2')}
                              value="checked_0_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>IoT, 센서</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_0_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_0_3')}
                              value="checked_0_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>스마트 팩토리</Typography>}/>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_1_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_1')}
                              value="checked_1_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>오토 캐드</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_2')}
                              value="checked_1_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>솔리드 웍스</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_3')}
                              value="checked_1_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>인벤터</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_4')}
                              value="checked_1_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>카티아</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_1_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_1_5')}
                              value="checked_1_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_2_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_1')}
                              value="checked_2_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>melsec</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_2')}
                              value="checked_2_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>LS 산전</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_3')}
                              value="checked_2_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>지멘스</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_2_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_2_4')}
                              value="checked_2_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_3_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_1')}
                              value="checked_3_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>장비 조립</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_2')}
                              value="checked_3_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>장비 배선</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_3')}
                              value="checked_3_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>공장 배선</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_4')}
                              value="checked_3_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>장비 유지,보수</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_3_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_3_5')}
                              value="checked_3_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>부품 수리</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_4_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_1')}
                              value="checked_4_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>범용 선반, 밀링</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_2')}
                              value="checked_4_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>CNC 선반</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_3')}
                              value="checked_4_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>MCT</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_4')}
                              value="checked_4_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>조각기</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_5')}
                              value="checked_4_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>프레스,판금</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_6}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_6')}
                              value="checked_4_6"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>cam 프로그램</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_4_7}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_4_7')}
                              value="checked_4_7"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_5_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_1')}
                              value="checked_5_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>원통 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_2')}
                              value="checked_5_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>평면 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_3')}
                              value="checked_5_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>로터리 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_4')}
                              value="checked_5_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>센터리스 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_5')}
                              value="checked_5_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>공구 연마</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_6}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_6')}
                              value="checked_5_6"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>호닝</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_7}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_7')}
                              value="checked_5_7"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>프로파일</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_5_8}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_5_8')}
                              value="checked_5_8"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_6_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_1')}
                              value="checked_6_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>알곤 용접</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_2')}
                              value="checked_6_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>레이저 용접</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_3')}
                              value="checked_6_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>레이저 마킹</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_6_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_6_4')}
                              value="checked_6_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_7_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_1')}
                              value="checked_7_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>건드릴</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_2')}
                              value="checked_7_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>열처리</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_3')}
                              value="checked_7_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>도금,아노다이징</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_4')}
                              value="checked_7_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>3차원 측정</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_7_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_7_5')}
                              value="checked_7_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    { this.state.checked_8_0 === true
                    ?
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_1}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_1')}
                              value="checked_8_1"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>영어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_2')}
                              value="checked_8_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>중국어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_3}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_3')}
                              value="checked_8_3"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>일본어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_4}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_4')}
                              value="checked_8_4"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>베트남어</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checked_8_5}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedChange('checked_8_5')}
                              value="checked_8_5"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoItemSelect}>언어 기타</Typography>}/>
                        </ListItem>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <ListItem className={classes.listItem}>
                        </ListItem>
                      </Grid>
                    </Grid>
                    :
                    null
                    }
                    <Divider/>
                    <ListItem className={classes.listItem}>
                    </ListItem>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItemTitle}>
                          <Typography className={classes.typoTitle}>약관동의(*)</Typography>
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider/>
                    <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checkedTerms_all}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedTerms('checkedTerms_all')}
                              value="checkedTerms_all"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoTerms}>전체동의</Typography>}/>
                        </ListItem>
                        <Divider/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <ListItem className={classes.listItem}>
                              <ListItemIcon className={classes.listItemIcon}>
                                <Checkbox
                                  checked={this.state.checkedTerms_0}
                                  color="default"
                                  className={classes.buttonChecked}
                                  onChange={this.handleCheckedTerms('checkedTerms_0')}
                                  value="checkedTerms_0"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={<Typography className={classes.typoTerms}>회원약관(*)</Typography>}/>                  
                            </ListItem>
                            <Divider/>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <ListItem className={classes.listItem}>
                              <Button variant="contained" className={classes.button} 
                                onClick={this.handleMemberTerms}>
                                <Typography className={classes.typoSubmit}>전문보기</Typography>  
                              </Button>
                            </ListItem>
                            <Divider/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container item sm={12} md={12} lg={12} xl={12} align="center" spacing={0}>
                          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <ListItem className={classes.listItem}>
                              <ListItemIcon className={classes.listItemIcon}>
                                <Checkbox
                                  checked={this.state.checkedTerms_1}
                                  color="default"
                                  className={classes.buttonChecked}
                                  onChange={this.handleCheckedTerms('checkedTerms_1')}
                                  value="checkedTerms_1"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={<Typography className={classes.typoTerms}>개인정보 수집 및 이용동의(*)</Typography>}/>
                            </ListItem>
                            <Divider/>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <ListItem className={classes.listItem}>
                              <Button variant="contained" className={classes.button} 
                                onClick={this.handleInfoAgreememnt}>
                                <Typography className={classes.typoSubmit}>전문보기</Typography>  
                              </Button>
                            </ListItem>
                            <Divider/>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <Checkbox
                              checked={this.state.checkedTerms_2}
                              color="default"
                              className={classes.buttonChecked}
                              onChange={this.handleCheckedTerms('checkedTerms_2')}
                              value="checkedTerms_2"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={<Typography className={classes.typoTerms}>마케팅/홍보의 수집 및 이용동의</Typography>}/>
                        </ListItem>
                      </Grid>
                    </Grid>
                  </List>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Card className={classes.card2}>
                  <Button variant="contained" /*color="primary"*/ className={classes.nextButton} 
                    onClick={this.handleNext}
                  >
                    <Typography className={classes.typoSubmit}>다음</Typography>
                  </Button>
                </Card>
              </Grid>
            </Grid>
          </main>
        </div>
        <Dialog open={this.state.postCodeDialogOpen} fullWidth={true} maxWidth = {'md'}>
          <DialogActions>
            <Button variant="contained" className={classes.button} 
              onClick={this.handleClose}>취소</Button>
          </DialogActions>
          <DialogContent>
            <DaumPostcode
              onComplete={this.handleAddress}
              /* ...props */
            />
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_1}>
        <DialogTitle>업체명을 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_2}>
        <DialogTitle>사업자 등록번호를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_3}>
        <DialogTitle>대표번호를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_4}>
        <DialogTitle>회사주소를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_5}>
        <DialogTitle>일감 분야를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_6}>
        <DialogTitle>당당자 이름을 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_7}>
        <DialogTitle>연락처를 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_8}>
        <DialogTitle>이메일을 입력해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_9}>
        <DialogTitle>보유기술에 체크해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_10}>
        <DialogTitle>상세 분야에 체크해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_11}>
        <DialogTitle>약관동의에 체크해주세요</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  //--------------------------------------------------------------
  // 2번째 페이지 내용

  // 리액트에서는 사용자가 값을 변경하면(event) 이런 함수를 만들어 주어야 한다.
  componentDidMount = async event => {
    // Facebook 로그인
    //await waitForInit();
  }

  // 경로 ( /user/:id) 
  getUser() {
    const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
    return API.get("upload", `/user/${currentAuthUserFromSession}`);
  }

  postUser(userContent) {
    return API.post("upload", "/user", {
      body: userContent
    });
  }

  handleGotoPage = () => {
    this.setState({alarmDialogOpen_17: false})
    this.props.history.push("/");
  }

  //--------------------------------------------------------------
  // Google 로그인
  signIn() {
    const { userHasAuthenticatedTrue } = this.props
    const ga = window.gapi.auth2.getAuthInstance();
    ga.signIn().then(
      googleUser => {
        this.getAWSCredentials(googleUser);
        userHasAuthenticatedTrue();
      },
        error => {
          console.log(error);
      }
    );
  }

  async getAWSCredentials(googleUser) {
    const { userHasAuthenticatedTrue } = this.props
    const { id_token, expires_at } = googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();
    let user = {
        email: profile.getEmail(),
        name: profile.getName()
    };

    const googleEmail = user.email;
    
    //this.setState({ isLoading: true });
    
    const credentials = await Auth.federatedSignIn(
        'google',
        { token: id_token, expires_at },
        user
    );

    sessionStorage.setItem("FCMLoginToggle", "true");
    localStorage.setItem("loginType", "google");
    localStorage.setItem("customerType1", "buyer");
    localStorage.setItem("customerType2", "company");
    localStorage.setItem("email", googleEmail);

    // 처음에 sign in 하면 유저 관련 초기 정보 DB에 저장하면 될 거 같음.
    try {
      const userID = await Auth.currentAuthenticatedUser();
      // console.log("userID: " + userID);
      const userID2 = await Auth.currentUserInfo(userID);
      // console.log("userID2: " + userID2);
      if(userID2 === null) {
        const userID3 = JSON.stringify(userID);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));
      
        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        //userHasAuthenticatedTrue();
      } else {
        const userID3 = JSON.stringify(userID2);
        // console.log("userID3: " + userID3);
        const userID4 = userID3.substr(userID3.indexOf('"id":"'));
        // console.log("userID4: " + userID4);
        const userID5 = userID4.substr(6);
        // console.log("userID5: " + userID5);
        const userID6 = userID5.substr(0, userID5.indexOf('"'));
      
        // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
        sessionStorage.setItem("currentUser", userID6);
        // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
        const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
        // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
        // currentAuthUser(currentAuthUserFromSession);
        //userHasAuthenticatedTrue();
      
        /*
        console.log("this.state.email :")
        console.log(this.state.email)
        */

        try{
          const user = await this.getUser();
          const { userId } = user;

          if(userId === currentAuthUserFromSession) {
            this.setState({alarmDialogOpen_17: true})
          } else {
            console.log("postUser 동작")
            try {
              await this.postUser({
                customerType1: "buyer",
                customerType2: "company",
                walletAddress: "default",
                companyName: this.state.companyName, 
                avatarimage: 'No_File', 
                ceoName: "default", 
                companyNumber: this.state.companyNumber, 
                companyPhoneNumber: this.state.companyPhoneNumber, 
                companyFaxNumber: "default",
                zoneCode: this.state.zoneCode, 
                address: this.state.address, 
                homepage: "default", 
                history: this.state.history, 
                name: this.state.name, 
                position: "default", 
                phoneNumber: this.state.phoneNumber, 
                email: googleEmail,
                adminConfirm: "false",
                level: '0',
                checkedMain: this.state.checkedMain,
                checkedSub: this.state.checkedSub,
                checkedTerms: this.state.checkedTerms,
                images: this.state.images,
              });
              this.props.history.push("/"); // 로그인 후 home 페이지로 이동
            } catch (error) {
              console.log(error)
              console.log("postuser 에러")
            }
          }
        } catch(e) {
          console.log(e)
        }
      }
    } catch (e) {
      console.log(e);
    }
    //this.setState({ isLoading: false });
    // this.props.onLogin(credentials);  // 이 코드를 넣어 줘야 로그인 되면서 페이지 넘어간다.
    // console.log('credentials', credentials);
  }
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  // Facebook 로그인
  // 사용자가 버튼을 클릭하면를 사용하여 로그인 프로세스를 시작 FB.login하고에서 로그인 상태가 변경 될 때까지 듣습니다.
  // statusChangeCallback. 이 메소드를 호출하는 동안을 설정하여 사용자의 공개 프로필과 이메일 주소를 원한다고 지정합니다 
  // {scope: "public_profile,email"}.
  statusChangeCallback = response => {
    if (response.status === "connected") {
      this.handleResponse(response.authResponse);
    } else {
      this.handleError(response);
    }
  };

  checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback);
  };

  handleFacebookClick = () => {
    window.FB.login(this.checkLoginState, {scope: "public_profile,email"});
  };

  handleError(error) {
    // alert(error);
    console.log(error);
  }

  // 사용자가 앱에 권한을 부여한 경우 Facebook (사용자의 이메일)에서받은 정보를 사용하여 
  // Auth.federatedSignInAWS Amplify 메서드를 호출합니다. 
  // 이것은 효과적으로 사용자를 로그인합니다.
  async handleResponse(data) {
    const { userHasAuthenticatedTrue } = this.props
    const { email, accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();
    const user = { email };
    let facebookEmail = '';

    await FB.api('/me', {fields: 'name,email'}, (response) => {
      facebookEmail  = response.email;
    });

    try {
      const response = await Auth.federatedSignIn(
        "facebook",
        { token, expires_at },
        user
      );
      // this.props.onLogin(response);

      // 처음에 sign in 하면 유저 관련 초기 정보 DB에 저장하면 될 거 같음.
      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: " + userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: " + userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
          // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
          // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          // currentAuthUser(currentAuthUserFromSession);
          userHasAuthenticatedTrue();
        } else {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
          const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
          // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          // currentAuthUser(currentAuthUserFromSession);
          userHasAuthenticatedTrue();

          sessionStorage.setItem("FCMLoginToggle", "true");
          localStorage.setItem("loginType", "facebook");
          localStorage.setItem("customerType1", "buyer");
          localStorage.setItem("customerType2", "company");
          localStorage.setItem("email", facebookEmail);
  

          try{
            const user = await this.getUser();
            const { userId } = user;
  
            if(userId === currentAuthUserFromSession) {
              this.setState({alarmDialogOpen_17: true})
            } else {
              try {
                await this.postUser({
                  customerType1: "buyer",
                  customerType2: "company",
                  walletAddress: "default",
                  companyName: this.state.companyName, 
                  avatarimage: 'No_File', 
                  ceoName: "default", 
                  companyNumber: this.state.companyNumber, 
                  companyPhoneNumber: this.state.companyPhoneNumber, 
                  companyFaxNumber: "default",
                  zoneCode: this.state.zoneCode, 
                  address: this.state.address, 
                  homepage: "default", 
                  history: this.state.history, 
                  name: this.state.name, 
                  position: "default", 
                  phoneNumber: this.state.phoneNumber, 
                  email: facebookEmail,
                  adminConfirm: "false",
                  level: '0',
                  checkedMain: this.state.checkedMain,
                  checkedSub: this.state.checkedSub,
                  checkedTerms: this.state.checkedTerms,
                  images: this.state.images,
                });
                this.props.history.push("/"); // 로그인 후 home 페이지로 이동
              } catch (error) {
                console.log(error)
                console.log("postuser 에러")
              }
            }
          } catch(e) {
            console.log(e)
          }
        }
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      this.handleError(e);
    }
  }
  //--------------------------------------------------------------

  handleValueChange = (e) => {
    let nextState = {};
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  }

  // Facebook 로그인 관련 메소드
  handleFbLogin = () => {
    userHasAuthenticatedTrue();
  };

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return (
      this.state.confirmationCode.length > 5
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    // event.preventDefault();
    // console.log("handleSubmit");
  
    //this.setState({ isLoading: true });

    this.setState({
      email: this.state.email,
      password: this.state.password
    });
  
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password
      });
      this.setState({
        newUser,
        validateform: false,
        alarmDialogOpen_15: true,
      });
    } catch (e) {
      // alert(e.message);
      console.log(e.message);
      if(e.message) {
        if(e.message === "An account with the given email already exists.") {
          this.setState({alarmDialogOpen_16: true});
        } else {
        this.setState({alarmDialogOpen_12: true});
        }
      }

      /*
      if(e.message == "Password did not conform with policy: Password must have uppercase characters") {
        this.setState({alarmDialogOpen_21: true});
      }
      if(e.message == 'Password did not conform with policy: Password must have lowercase characters') {
        this.setState({alarmDialogOpen_22: true});
      }
      if(e.message == 'Password did not conform with policy: Password must have numeric characters') {
        this.setState({alarmDialogOpen_23: true});
      }
      if(e.message == 'Password did not conform with policy: Password must have symbol characters') {
        this.setState({alarmDialogOpen_24: true});
      }
      if(e.message == "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\S]+.*[\S]+$; Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6") {
        this.setState({alarmDialogOpen_25: true});
      }
      if(e.message == "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6") {
        this.setState({alarmDialogOpen_25: true});
      }
      if(e.message == "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6") {
        this.setState({alarmDialogOpen_25: true});
      }
      if(e.message == "Password did not conform with policy: Password not long enough") {
        this.setState({alarmDialogOpen_25: true});
      }
      */
    }
  
    //this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    // event.preventDefault();
    // console.log("handleConfirmationSubmit");

    if(this.state.confirmationCode.length != 6) {
      this.setState({alarmDialogOpen_13 : true});
    }

    const { userHasAuthenticatedTrue } = this.props
  
    // this.setState({ isLoading: true });
  
    if(this.state.confirmationCode.length === 6) {
    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      // 처음에 sign in 하면 유저 관련 초기 정보 DB에 저장하면 될 거 같음.
      try {
        const userID = await Auth.currentAuthenticatedUser();
        // console.log("userID: " + userID);
        const userID2 = await Auth.currentUserInfo(userID);
        // console.log("userID2: " + userID2);
        if(userID2 === null) {
          const userID3 = JSON.stringify(userID);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
          // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
          // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          // currentAuthUser(currentAuthUserFromSession);
          userHasAuthenticatedTrue();
        } else {
          const userID3 = JSON.stringify(userID2);
          // console.log("userID3: " + userID3);
          const userID4 = userID3.substr(userID3.indexOf('"id":"'));
          // console.log("userID4: " + userID4);
          const userID5 = userID4.substr(6);
          // console.log("userID5: " + userID5);
          const userID6 = userID5.substr(0, userID5.indexOf('"'));
  
          // currentUser란 키(key) 값을 사용하여 해당 텍스트를 저장함
          sessionStorage.setItem("currentUser", userID6);
          // 키에 저장된 값을 반환. 여기서는 userID6 출력됨
          // const currentAuthUserFromSession = sessionStorage.getItem("currentUser");
          // console.log('currentAuthUserFromSession: ' + currentAuthUserFromSession);
          // currentAuthUser(currentAuthUserFromSession);
          userHasAuthenticatedTrue();
        }
      } catch (e) {
        console.log(e);
      }

      sessionStorage.setItem("FCMLoginToggle", "true");
      localStorage.setItem("loginType", "email");
      localStorage.setItem("customerType1", "buyer");
      localStorage.setItem("customerType2", "company");
      localStorage.setItem("email", this.state.email);

      try {
        await this.postUser({
          customerType1: "buyer",
          customerType2: "company",
          walletAddress: "default",
          companyName: this.state.companyName, 
          avatarimage: 'No_File', 
          ceoName: "default", 
          companyNumber: this.state.companyNumber, 
          companyPhoneNumber: this.state.companyPhoneNumber, 
          companyFaxNumber: "default",
          zoneCode: this.state.zoneCode, 
          address: this.state.address, 
          homepage: "default", 
          history: this.state.history, 
          name: this.state.name, 
          position: "default", 
          phoneNumber: this.state.phoneNumber, 
          email: this.state.email,
          adminConfirm: "false",
          level: '0',
          checkedMain: this.state.checkedMain,
          checkedSub: this.state.checkedSub,
          checkedTerms: this.state.checkedTerms,
          images: this.state.images,
        });

        this.props.history.push("/");
      } catch (error) {
        console.log(error)
      }
    } catch (e) {
      //alert(e.message);
      console.log(e.message);
      if(e.message) {
        this.setState({alarmDialogOpen_14: true});
      }
      // this.setState({ isLoading: false });
    }
    } 
  }

  /*
  generatePrivateKey = () => {
    const { privateKey } = cav.klay.accounts.create()
    this.setState({ privateKey })
  }
 */

  handleConfirmKeyPress = (e) => {
    // console.log("e.charCode :");
    // console.log(e.charCode);

    // key 13 = 엔터를 의미
    if(e.charCode === 13) {
      this.handleConfirmationSubmit()
    }
  }

  renderSignUpForm() {
    const { classes } = this.props
    const { warningMessage } = this.state

    const connector = <StepConnector 
      classes={{
        //active: classes.connectorActive,
        //completed: classes.connectorCompleted,
        line: classes.connectorLine
      }} />;

    const stepper = (
      <div className={classes.stepperRoot}>
        <Stepper nonLinear activeStep={this.state.activeStep} className={classes.stepper} connector={connector}>
          {this.steps.map((label, index) => (
            <Step key={label} className={classes.step}>
                <StepLabel
                  completed={this.state.completedStep[index]}
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                    iconContainer: classes.iconContainer
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.stepLabelRoot,
                      completed: classes.completed,
                      active: classes.active,
                      text: classes.text
                    }
                  }}
                >
                </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );

    return (
      <div className={classes.root}>
        {/* AppBar는 항상 위쪽에 존재하도록 해준다는 뜻 */}
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar className={classes.toolBar} variant="dense">
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  color="inherit"
                  aria-label="back"
                  onClick={this.handleGoBack}
                >
                  <SVGIcon className={classes.svgIcon} name="uploadBackIcon" width={25} fill="#696969" />
                </IconButton>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <CardMedia
                  className={classes.logoMedia}
                  // image="/static/images/logo/app.svg"
                  image="/images/logo/app.svg"
                  aria-label="Recipe" 
                />
              </Grid>
              <Grid item xs={7} sm={7} md={4} lg={4} xl={4}>
                <Typography className={classes.appbarTitle} align='center' noWrap>
                  오투공 - Online To 공장
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={4} lg={4} xl={4}>
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
        </AppBar>
        <main className={classes.content2}>
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Grid item xs={8} sm={8} md={4} lg={3} xl={3} align="center">
                <div>{stepper}</div>
              </Grid>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={4} xl={4}>
              {/*<div className="LoginForm">*/}
              <div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.labelTop}  htmlFor="email">
                      이메일 주소
                    </label>
                  }
                  {this.state.newUser === null
                  ?
                  <input
                    className={classes.Input__input}
                    id="email"
                    type="email"
                    name="email"
                    autoFocus
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="이메일주소 작성 해주세요"
                    //onKeyPress={(e) => this.handleSubmitKeyPress(e)}
                    autoComplete="off"
                  />
                  :
                  <input
                    className={classes.Input__input}
                    id="email"
                    type="email"
                    name="email"
                    autoFocus
                    value={this.state.email}
                    //onChange={this.handleChange}
                    //placeholder="이메일주소 작성 해주세요"
                    //onKeyPress={(e) => this.handleSubmitKeyPress(e)}
                    autoComplete="off"
                    disabled
                  />
                  }
                </div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.label} htmlFor="password">
                      비밀번호 (숫자, 특수문자 포함)
                    </label>
                  }
                  {this.state.newUser === null
                  ?
                  <input
                    className={classes.Input__input}
                    id="password"
                    type="password"
                    name="password"
                    autoFocus
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="비밀번호 작성 해주세요(숫자, 특수문자 포함)"
                    //onKeyPress={(e) => this.handleSubmitKeyPress(e)}
                    autoComplete="off"
                  />
                  :
                  <input
                    className={classes.Input__input}
                    id="password"
                    type="password"
                    name="password"
                    autoFocus
                    value={this.state.password}
                    //onChange={this.handleChange}
                    placeholder="비밀번호 작성 해주세요(대문자, 특수문자 포함)"
                    //onKeyPress={(e) => this.handleSubmitKeyPress(e)}
                    autoComplete="off"
                    disabled
                  />
                  }
                </div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.label} htmlFor="confirmPassword">
                      비밀번호 확인 (숫자, 특수문자 포함)
                    </label>
                  }
                  {this.state.newUser === null
                  ?
                  <input
                    className={classes.Input__input}
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    autoFocus
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    placeholder="비밀번호 작성 해주세요(숫자, 특수문자 포함)"
                    //onKeyPress={(e) => this.handleSubmitKeyPress(e)}
                    autoComplete="off"
                  />
                  :
                  <input
                    className={classes.Input__input}
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    autoFocus
                    value={this.state.confirmPassword}
                    //onChange={this.handleChange}
                    placeholder="비밀번호 작성 해주세요(숫자, 특수문자 포함)"
                    //onKeyPress={(e) => this.handleSubmitKeyPress(e)}
                    autoComplete="off"
                    disabled
                  />
                  }
                </div>
                <div className={classes.LoginForm__input}>
                  {
                    <label className={classes.label} htmlFor="confirmationCode">
                      확인코드 (이메일로부터 확인코드 확인)
                    </label>
                  }
                  <div>
                  <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                      {this.state.newUser === null
                      ?
                      <input
                        className={classes.Input__input}
                        id="confirmationCode"
                        type="tel"
                        name="confirmationCode"
                        autoFocus
                        value={this.state.confirmationCode}
                        //onChange={this.handleChange}
                        placeholder="이메일로부터 확인코드 입력해주세요"
                        //onKeyPress={(e) => this.handleConfirmKeyPress(e)}
                        autoComplete="off"
                        disabled
                      />
                      :
                      <input
                        className={classes.Input__input}
                        id="confirmationCode"
                        type="tel"
                        name="confirmationCode"
                        autoFocus
                        value={this.state.confirmationCode}
                        onChange={this.handleChange}
                        placeholder="이메일로부터 확인코드 입력해주세요"
                        onKeyPress={(e) => this.handleConfirmKeyPress(e)}
                        autoComplete="off"
                      />
                      }
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
                      {this.validateForm() === true && this.state.validateform === true
                      ?
                      <AWSLoginButton
                        className={classes.codeButtonTrue}
                        title="코드발송"
                        disabled={!this.validateForm()}
                        onClick={this.handleSubmit}
                      />
                      :
                      <AWSLoginButtonFalse
                        className={classes.codeButtonfalse}
                        title="코드발송"
                        disabled={!this.validateForm()}
                        //onClick={this.handleSubmit}
                      />
                      }
                    </Grid>
                  </Grid>
                </div>
                </div>
                {this.state.newUser === null
                ?
                <AWSLoginButton
                  // className="LoginForm__button"
                  className={classes.submitButtonFalse}
                  title="회원가입"
                  disabled={!this.validateConfirmationForm()}
                  onClick={this.handleConfirmationSubmit}
                />
                :
                <AWSLoginButtonFalse
                  // className="LoginForm__button"
                  className={classes.submitButtonTrue}
                  title="회원가입"
                  disabled={!this.validateConfirmationForm()}
                  onClick={this.handleConfirmationSubmit}
                />
                }
                {/*
                <GoogleLoginButton
                  className={classes.googleButton}
                  title="구글로 가입하기"
                  // disabled={this.state.isLoading}
                  onClick={this.signIn}
                />
                <FaceBookLoginButton
                  className={classes.facebookButton}
                  title="페이스북으로 가입하기"
                  // disabled={this.state.isLoading}
                  onClick={this.handleFacebookClick}
                />
                */}
              </div>
            </Grid>
          </Grid>
        </main>
        <Dialog open={this.state.alarmDialogOpen_12}>
        <DialogTitle>비밀번호 작성 방법</DialogTitle>
        <DialogContent>1. 6자리 이상 입력해주세요</DialogContent>
        <DialogContent>2. 소문자를 포함시켜주세요</DialogContent>
        <DialogContent>3. 숫자를 포함시켜주세요</DialogContent>
        <DialogContent>4. 특수문자를 포함시켜주세요</DialogContent>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_13}>
        <DialogTitle>코드를 정확하게 다시 입력해주세요</DialogTitle>
        <DialogContent>코드 숫자는 6자리 입니다.</DialogContent>
        <DialogContent>빈칸도 글자로 간주 합니다.</DialogContent>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_14}>
        <DialogTitle>코드를 잘못 입력 하셨습니다.</DialogTitle>
        <DialogContent>영업일 기준 2일 안에 다시 등록 실패한 이메일주소를 등록 가능하게 해드립니다.</DialogContent>
        <DialogContent>궁금하신점 있으시면 오투공으로 연락 부탁드리겠습니다.</DialogContent>
        <DialogContent>전화번호 : 043)714-3378</DialogContent>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_15}>
        <DialogTitle>이메일로 코드를 발송하였습니다.</DialogTitle>
        <DialogContent>확인된 코드를 입력 해주세요.</DialogContent>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_16}>
        <DialogTitle>기존에 존재하는 계정입니다.</DialogTitle>
        <DialogContent>다른 이메일 주소를 입력 해주세요.</DialogContent>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleClose}>확인</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.alarmDialogOpen_17}>
        <DialogTitle>기존에 존재하는 계정입니다.</DialogTitle>
          <DialogActions>
            <Button variant="contained" /*color="primary"*/ className={classes.dialogButton} 
              onClick={this.handleGotoPage}>확인</Button>
          </DialogActions>
        </Dialog>
      </div>
    )       
  }

  handleSubmitKeyPress = (e) => {
    // console.log("e.charCode :");
    // console.log(e.charCode);

    // key 13 = 엔터를 의미
    if(e.charCode === 13) {
      this.handleSubmit()
    }
  }

  // 로딩중일때 progress바 사용(material ui)
  progress = () => {
    const { completed } = this.state;
    this.setState({ completed: completed >= 100 ? 0 : completed + 1 });
  }

  componentDidMount() {
    this._isMounted = true;

    // this.timer = setInterval(this.progress, 20);  // timer 사용해서 0.02초마다 progress 함수가 실행되게끔 설정해준 것임.
    if (this._isMounted) {
      this.timer = setInterval(this.progress, 20);
    }
  }

  render() {
    return (
      <div>
        {this.state.pageStep === 0
        ?
        this.selectField()
        :
        this.state.pageStep === 1
        ?
        this.renderSignUpForm()
        :
        null
        }
      </div>
    )
  }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
  //email: state.auth.email,
  //password: state.auth.password,
})

const mapDispatchToProps = (dispatch) => ({
  //awslogin: () => dispatch(authActions.awslogin()),
  userHasAuthenticatedTrue: () => dispatch(authActions.userHasAuthenticatedTrue()),
  userHasAuthenticatedFalse: () => dispatch(authActions.userHasAuthenticatedFalse()),
})

AWSSignupForm_Buyer_Company = withStyles(styles, {withTheme: true})(AWSSignupForm_Buyer_Company)
AWSSignupForm_Buyer_Company = connect(mapStateToProps, mapDispatchToProps)(AWSSignupForm_Buyer_Company)
// 하위 컴포넌트에서 this.props.history.push("/") 사용시 withRouter 써야 한다.
export default withRouter(AWSSignupForm_Buyer_Company)